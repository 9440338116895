import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Bottom from './Bottom'
import Logo from './Logo'
import { colors, media } from '../../style-utils'
import BaseSection from '../styles/Section'
import BaseOuterSection from '../styles/OuterSection'

import Facebook from '../../assets/social/facebook-dark.png'
import Linkedin from '../../assets/social/linkedin-dark.png'
import Twitter from '../../assets/social/twitter-dark.png'

const OuterSection = styled(BaseOuterSection)`
  background-color: transparent;
  ${media.desktop`
    background-color: transparent;
  `};
`

const Section = styled(BaseSection)`
  margin: 0 auto;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 0;
  ${media.desktop`
    text-align: left;
    padding-top: 3rem;
  `};
`

const MenuContainer = styled.div`
  float: right;
  margin-top: 20px;
  & > * {
    vertical-align: middle;
  }
`

const Socials = styled.div`
  display: none;
  ${media.desktop`
    line-height: 1;
    text-align: left;
    display: inline-block;
    margin-left:60px;
  `};
`

const SiteLogo = styled(Logo)`
  float: left;
`

const SocialLink = styled.a`
  margin-left: 14px;
  &:first-child {
    margin-left: 0;
  }
`

const SectionHeader = ({ links }) => (
  <OuterSection>
    <Section>
      <SiteLogo />
      <MenuContainer>
        <Bottom />
        <Socials>
          <SocialLink href={links.twitter} target="_blank">
            <img src={Twitter} alt="Twitter" />
          </SocialLink>
          <SocialLink href={links.facebook} target="_blank">
            <img src={Facebook} alt="Facebook" />
          </SocialLink>
          <SocialLink href={links.linkedIn} target="_blank">
            <img src={Linkedin} alt="LinkedIn" />
          </SocialLink>
        </Socials>
      </MenuContainer>
    </Section>
  </OuterSection>
)

SectionHeader.propTypes = {
  links: PropTypes.shape({
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    linkedIn: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
}

export default SectionHeader
